// =====================================================================
// Media Query Mixins
// For min/max pixel values



@mixin min($min: 0, $media: "screen") {
  $query: "only " + $media + " and (min-width: " + ($min) + ")";
  @media #{$query} {
    @content;
  };
}


@mixin max($max: 3000, $media: "screen") {
  $query: "only " + $media + " and (max-width: " + ($max - 1) + ")";
  @media #{$query} {
      @content;
  };
}

@mixin minmax($min: null, $max: null, $media: "screen") {
  $query: "only " + $media;

  @if $min != null and $max != null {
      $query: "only " + $media + " and (min-width: " + $min + ") and (max-width: " + ($max - 1) + ")";
  } @else if $min == null and $max != null {
      $query: "only " + $media + " and (max-width: " + ($max - 1) + ")";
  } @else if $min != null and $max == null {
      $query: "only " + $media + " and (min-width: " + $min + ")";
  }

  @media #{$query} {
      @content;
  };
}

@mixin cta-text {
 font-size: $font-size-sm !important; 
 font-weight: 600 !important;
 text-transform:uppercase !important; 
 color: $secondary-color !important;
 letter-spacing: 0.04rem !important;
}

.cta-text {
  @include cta-text;
}
