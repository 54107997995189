
@keyframes fadeInRight {
  0% {
    opacity: 0;
    left: 20%;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}

@keyframes slideInUp {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.slideInUp {
  animation-name: slideInUp;
}



// @keyframes fadeIn {
//     from { opacity: 0; }
//     to   { opacity: 1; }
// }

// @keyframes para-fall {
//     0% {
//       top: -10%
//     }

//     100% {
//       top: 100%
//     }
// }

// @keyframes para-shake {

//     0%,
//     100% {
//         transform: translateX(0)
//     }

//     50% {
//         transform: translateX(80px)
//     }
// }
