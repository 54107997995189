//
// Base Styles
// --------------------------------------------------

html {
  margin:0; 
}

body {
  overflow-x:hidden;
  margin:0; 
  font-family: $primary-font;
  color: $primary-color;
}

.hover {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}

// Colors
@if variable-exists(theme-colors) {
  @each $color, $value in $theme-colors {
    .bg-#{$color} {
      background-color: $value !important;
    }
    .text-#{$color} {
      color: $value !important;
    }   
    .link-#{$color} a:not(.btn){
      color: $value;
      //@include darken-on-hover($value);
    } 
  }
}



//
// Typography
// --------------------------------------------------

a {
  transition: all 0.2s ease-out;
  //color: $default-color;
}


.fa {
  vertical-align: middle;
}

a[href^="tel"]{ color:inherit; text-decoration: none !important; }

svg {
  width: 1rem;
}


strong {
  font-weight: bold;
}

.pre-line {
  white-space: pre-line;
}

.font-weight-semibold {
  font-weight: 600;
}
.font-weight-bold {
  font-weight: 700;
 // font-weight: 900;
}
.font-size-large, .font-size-lg {
  font-size: $font-size-lg !important;
}

.font-size-xs {
  font-size: $font-size-xs !important;
}
.font-size-sm, .font-size-small {
  font-size: $font-size-sm !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.readmore {
  text-transform: uppercase;
  font-weight: bold;
}

ul {
  padding-left: 1rem;
}

h2, .h2 {
  font-size: 1.5rem; 
  font-weight: 700;
  margin-bottom: 0.75rem;
  line-height: 1.3;
}

h1, .h1 {
  font-size: 2.7rem; 
  font-weight: 700;
  margin-bottom: 1.5rem;
}

h3, .h3 {
  font-weight: 600;
  margin-bottom: 0.75rem;
  line-height: 1.2;
  font-size: 1.3rem;
}

.btn {
  border-radius: 0;
  background: transparent;
  border:1px solid $highlight-color;
  color: $white;
  font-weight: 300;
  text-transform: none;
  padding: .475rem 1.95rem 0.275rem;
  &:hover, &:focus, &:active, &:hover:active {
    background-color: $secondary-color !important;
    border-color: $secondary-color;
    box-shadow: none !important;
  }
}

.alert {
  background: $red;
  color: $white;
  border-radius: 0;
  border: none;
  z-index: 9999;
}
