@import url('https://fonts.googleapis.com/css?family=Khula:300,400,600,700');

// Bootstrap
@import "bootstrap/functions";
@import 'bootstrap-variables';
@import 'bootstrap/variables';
@import 'bootstrap/mixins';
@import 'bootstrap/utilities';

// Libraries
@import 'libraries/hamburgers/hamburgers';

// Theme
@import "mixins";
@import "base";
@import "animations";
@import "layout";


// INTRO
.layout-hero {
  position: relative;
  p {
    margin-bottom: 0;
  }
  .hero-content {
    background-color: rgba($primary-color, 0.7);
    height: 100%;    
    z-index: 3;
    justify-content: center;
    @include media-breakpoint-up(md) {
      width: 50%;
      flex: 0 0 50%;
      .inner {
        max-width: 35rem;
      }
    }
  }
  .mission-statement {
    font-size: 1.5rem;
    line-height: 1.4;
  }
  .tagline {
    font-size: 1rem;
  }
  blockquote {
    line-height: 1.2;
    font-size: 2rem;
    &:before, &:after {
      content: "\201C";
      color: $aqua-medium;
      font-size: 2rem;
      font-weight: 900;
      display: block;
      height: 1.8rem;
      //background-color: red;
    }
    &:after {
      height:1.2rem;
      margin-top: 0.3rem;
      content: "\201D";
    }
  }
  .carousel {
    position: absolute;
    top:0;
    z-index: 1;
    height: 100%;
    .carousel-inner {
      height: 100%;
    }
    //display: none;
    .carousel-item {
      width: 100vw;
      height: 100%;
      position: relative;
      background-size: cover;
      background-position: top center;
    }
  }
  .hero-footer {
    font-size: 1.8rem;
    line-height: 1.3;
  }

  // Macbook
  @include media-breakpoint-down(lg) {
    blockquote { font-size: 1.6rem; }
    .hero-footer { font-size: 1.4rem; }  
    .mission-statement { font-size: 1.2rem; }
    .tagline { font-size: 1rem; }
  }

  // iPad
  @include media-breakpoint-down(md) {
    blockquote { font-size: 1.8rem; }
  }

  // Mobile
  @include media-breakpoint-down(sm) {
    .hero-content {
      height: calc(100vh - #{$navbar-height-collapsed});
      background: none;
      justify-content: flex-end;
      .inner {
        background-color: rgba($primary-color, 0.7);
        padding: 2rem 1.5rem;
      }
    }
    .carousel { height: calc(100vh - #{$navbar-height-collapsed}); }
    blockquote { font-size: 1.2rem; }
    .hero-footer { font-size: 1.2rem; }  
    .mission-statement { font-size: 1rem; }
    .tagline { font-size: $font-size-xs; }

  }

}

// Layout Style with image
.layout-image {
  background-repeat: no-repeat;
  background-size: 50%;

  @include media-breakpoint-down(md) {
    background-size: 39%;
    background-position: left 5.2rem;
  }

  @include media-breakpoint-down(sm) {
    background: none !important;
    .image {
      background-size: cover;
      background-position: center bottom;
      height: 400px;
    }
  }

  h2 {
    color: $secondary-color;
  }

  p {
    font-weight: 300;
    font-size: 1.1rem;
    margin-bottom: 0.75rem;
  }

  p strong {
    font-weight: 600;
  }

}

// WHY PARACHUTE
#whyparachute {
  background-image: url(../images/square-restaurateur.jpg);
  @include media-breakpoint-down(sm) {
    .image {
      background-image: url(../images/square-restaurateur.jpg);
    }
  }
}

// WE'RE WITH YOU
#wearewithyou {
  p {
    line-height: 1.3;
  }
}

// HOW WE HELP
#howwehelp {
  p {
    font-weight: 300;
    font-size: 0.9rem;
  }
  .howwehelp-action {
    .inner {
      border: 2px solid rgba(mix($aqua, $white, 50%), 0.2);
    }
  }
}

//OUR PROMISE 
#ourpromise {
  background-image: url(../images/square-office-chat.jpg);

  @include media-breakpoint-down(sm) {
    .image {
      background-image: url(../images/square-office-chat.jpg);
      background-position: right top;
    }
  }
}

// TESTIMONIALS
#testimonials {
  p {
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
  }
  .carousel-indicators li {
    width: 10px;
    height: 10px;
    text-indent: -999px;
    background-color: $primary-color;
    opacity: .3;
    border-radius: 50%;
    &.active {
      opacity: .5;
    }
  }
}


// Social
.social svg {
  width: 1.5rem;
}
.social a:hover svg path {
  fill: $highlight-color;
}

// FORMS
.form-control {
  border: none;
  border-bottom:1px solid $highlight-color;
  background: transparent !important;
  border-radius: 0;
  color: $white;
  padding-left: 0;
  padding-bottom: 0rem;
  padding-top: 1rem;
  height: 2.5rem;
  &:focus {
    outline: 0 !important;
    box-shadow: none;
    color: $white;
  }
}

textarea {
  min-height: 8rem;
}

.form-group {
  label {
    position: absolute;
    left: 0;
    width: 100%;
    //top: 9px;
    color: #fff;
    transition: .3s;
    z-index: 0;
    bottom: 0;
    margin-bottom: 0;
    font-weight: 300;
  }

  &.form-group-textarea label {
    top: 0;
    top: 10px;
    bottom: auto;
    width: 90px;

  }

  &.focus label {
    top: -0.3rem;
    font-size: 0.75rem;
    color: lighten($highlight-color, 15%);//tint($highlight-color, 50%);
    transition: 0.3s;
  }
}

#thankyou {
  @include media-breakpoint-up(md) {
    min-height: 350px;
  }
}

#thankyou, #form-error {
  display: none;
}



// Type Styling for body content
.body-content {
  a:not(.btn) {
    position: relative;
    color: $gray-600;
    &:after {
      content: "";
      position: absolute;
      left:0;
      right: 0;
      bottom:0.4rem;
      width: 100%;
      border-top: 2px solid rgba($primary-color, 0.3);//$gray-300;//t lighten($default-color, 25%);
    }
    &:hover, &:focus {
      text-decoration: none !important;
      &:after {
        border-top-color: mix($secondary-color, $primary-color, 80%);
      } 
    }
  }

  h2 + h3, h2 + h4 {
    margin-top: 2rem;
  }
  p + h2 {
    margin-top: 3rem;
  }

  p + h3, p + h4 {
    margin-top: 2rem;
  }

 }

// Mobile
@include media-breakpoint-down(sm) {
  section .col-12 {
    padding-left: 25px;
    padding-right: 25px;
  }
  h2, .h2 {
    font-size: 1.3rem; 
  }

  h1, .h1 {
    font-size: 2.4rem; 
  }

  h3, .h3 {
    font-size: 1.2rem;
  }
  #testimonials p, .layout-image p {
    font-size: 1rem;
  }
  .font-size-large, .font-size-lg{
    font-size: 1.2rem !important;
  }
}